import React from "react";
import {
  ContainerInner,
  Container,
  CountriesNames,
  CompanyName,
  AddressOffice,
  Phone,
  Email,
  Location,
  LocationContainer,
  Contact,
} from "./styles";

const ElectricalDistributor = ({
  distributor,
  distributorLocation = [],
  isEven,
}) => {
  return (
    <Container isEven={isEven}>
      <ContainerInner>
        <CompanyName>{distributor}</CompanyName>
        <LocationContainer>
          {distributorLocation?.map(
            (
              {
                distributorCountry,
                distributorAddress,
                distributorPhone,
                distributorEmail,
                distributorContact,
              },
              idx
            ) => (
              <Location key={`${idx}-distributor-${distributor}`}>
                {distributorAddress && (
                  <AddressOffice>{distributorAddress}</AddressOffice>
                )}
                {distributorCountry && (
                  <CountriesNames>{distributorCountry}</CountriesNames>
                )}
                {distributorPhone && (
                  <Phone href={`tel:${distributorPhone}`}>
                    {distributorPhone}
                  </Phone>
                )}
                {distributorEmail && (
                  <Email href={`mailto:${distributorEmail}`}>
                    {distributorEmail}
                  </Email>
                )}
                {distributorContact?.contactLink && (
                  <Contact
                    href={distributorContact.contactLink}
                    target="_blank"
                  >
                    {distributorContact?.contactLabel ||
                      distributorContact.contactLink}
                  </Contact>
                )}
              </Location>
            )
          ) ?? null}
        </LocationContainer>
      </ContainerInner>
    </Container>
  );
};

export default ElectricalDistributor;
