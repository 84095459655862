import styled from "styled-components";
import { media } from "components/Theme/default-theme";

export const Contact = styled.a`
  text-decoration: underline;

  :hover {
    color: #ff6000;
  }
`;

export const Email = styled.a`
  text-decoration: none;

  :hover {
    color: #ff6000;
  }
`;

export const Phone = styled.a`
  text-decoration: none;

  :hover {
    color: #ff6000;
  }
`;

export const AddressOffice = styled.h3``;

export const CountriesNames = styled.h3`
  margin: 0 0 5px;
  text-transform: uppercase;
`;

export const Location = styled.div`
  display: inherit;
  flex-direction: column;
  justify-conent: flex-start;
  width: 100%;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const CompanyName = styled.h3`
  font-weight: 500 !important;
  margin: 0 2.5%;
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  color: #696057;
  margin: 0 auto;
  width: 88%;

  h3,
  a {
    font-style: normal;
    font-family: Gotham, serif;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    min-height: 1em;
    width: fit-content;
  }
`;

export const Container = styled.div`
  background: ${({ isEven }) => (isEven ? "#F2F2F2" : "#fff")};
  width: 100%;
  min-height: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;

  ${media.laptop} {
    ${ContainerInner} {
      width: 50%;
    }
  }
`;
