import { graphql } from "gatsby";
import React from "react";
import Layout from "components/Layout";
import ElectricalDistributorsView from "ducks/ElectricalDistributors";

export default function ElectricalDistributorsPage({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const results = edges
    .map(
      (
        {
          node: {
            frontmatter: { uid, distributor = "", distributorLocation = [] },
          },
        },
        index
      ) => ({ uid, distributor, distributorLocation })
    )
    .sort((a, b) => (a.distributor > b.distributor ? 1 : -1));
  return (
    <Layout>
      <ElectricalDistributorsView data={results} />
    </Layout>
  );
}

export const query = graphql`
  query electricalDistributors {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/electrical-distributors/**" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            uid
            distributor
            distributorLocation {
              distributorCountry
              distributorAddress
              distributorPhone
              distributorEmail
              distributorContact {
                contactLabel
                contactLink
              }
            }
          }
        }
      }
    }
  }
`;
