import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "components/Theme/default-theme";
import LearnMoreBtn from "components/LearnMoreBtn";
import OurInternationalDistributorsImg from "../../images/our-international-distributors.png";

export const HeroBackground = styled.div`
  height: 408px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 69px 0;
  background-image: url(${OurInternationalDistributorsImg}),
    linear-gradient(135.39deg, #7a7a7a 0.67%, #3f3e3e 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const HeroTitle = styled.h3`
  font-family: Gotham, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  margin: 0 0 30px;
`;

export const HeroSubtitle = styled.p`
  font-family: Gotham, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  margin: 0 1.75rem;
`;

export const Link = styled(LearnMoreBtn)`
  text-transform: initial;
  font-size: 18px;
  line-height: 27px;
`;

export const ElectricalDealersList = styled.div``;

export const Title = styled.h4`
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  color: #382e2b;
  width: 100%;
`;

export const Paragraph = styled(ReactMarkdown)`
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: #382e2b;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  min-height: 85vh;
  height: 100%;
  ${media.laptop} {
    ${HeroBackground} {
      ${HeroSubtitle} {
        max-width: 500px;
      }
    }
  }
`;

export const Loader = styled.div`
  padding: 15px;
  text-align: center;
`;

export const CountrySectionTitle = styled.div`
  padding: 10px 0;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  background: #979797;
`;

export const NotFoundSection = styled.div`
  margin: 50px;
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #382e2b;
`;

export const ContainerInner = styled.div`
  padding: 0 15px;
  ${media.laptop} {
    max-width: 880px;
    margin: 0 auto;
  }
`;
